
































































































import { Component, Vue } from 'vue-property-decorator';
import * as api from '../gen/api';

@Component({
  components: {}
})
export default class P0150 extends Vue {
  private isResent = false;
  private errMsg = '';

  private async resendEmail() {
    const config = await this.$getConfigWithToken(this.$auth);
    const curUserApi = new api.CurrentUserApi(config);
    try {
      await curUserApi.postResendEmail();
      this.isResent = true;
    } catch (err) {
      this.$tokenErrHandler(err);
      this.errMsg = this.$msg.get('2000021');
    }
  }
}
